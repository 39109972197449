import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Footer, IconFacebook, IconTwitter, IconInstagram, IconYoutube, IconTiktok } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/footer",
  "title": "Footer",
  "navTitle": "Footer"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Footer title="Helsinki Design System" footerAriaLabel="HDS Footer" mdxType="Footer">
    <Footer.Navigation navigationAriaLabel="HDS Footer navigation">
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
    </Footer.Navigation>
    <Footer.Utilities backToTopLabel="Back to top">
      <Footer.SoMe>
        <Footer.Item icon={<IconFacebook mdxType="IconFacebook" />} />
        <Footer.Item icon={<IconTwitter mdxType="IconTwitter" />} />
        <Footer.Item icon={<IconInstagram mdxType="IconInstagram" />} />
        <Footer.Item icon={<IconYoutube mdxType="IconYoutube" />} />
        <Footer.Item icon={<IconTiktok mdxType="IconTiktok" />} />
      </Footer.SoMe>
      <Footer.Item label="Contact us" />
      <Footer.Item label="Give feedback" />
    </Footer.Utilities>
    <Footer.Base copyrightHolder="Copyright" copyrightText="All rights reserved">
      <Footer.Item label="Link" />
      <Footer.Item label="Link" />
      <Footer.Item label="Link" />
      <Footer.Item label="Link" />
      <Footer.Item label="Link" />
    </Footer.Base>
  </Footer>
    </PlaygroundPreview>
    <p>{`Footer stays consistent throughout the site and is often the place where users scroll to if they feel lost or don't find what they are looking for. It's a good place provide the user an additional way to navigate the service and to information that is hard to find through primary navigation, for example contact info, copyright and legal statements. The footer can also be used to engage the user with actions and links to social media.`}</p>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`It is strongly recommended to always include the footer component in your service and on every page of the service.`}</strong></li>
      <li parentName="ul">{`Always position the footer to the bottom of the page.`}</li>
      <li parentName="ul">{`Footer component is built to follow HDS `}<InternalLink href="/foundation/design-tokens/breakpoints" mdxType="InternalLink">{`breakpoint tokens`}</InternalLink>{`. While it is recommended to follow HDS breakpoint tokens, you may alter footer width to fit your service's needs.`}</li>
      <li parentName="ul">{`Keep the order of footer sections (navigation, utility, base) and actions consistent. Do not change the default order without a good reason.`}</li>
      <li parentName="ul">{`The footer navigation should follow the same structure and labelling as the primary navigation. All top level pages included in the primary navigation should also be visible in footer navigation.`}</li>
      <li parentName="ul">{`If sub-pages are included in the footer, they should also follow the same structure and labelling as in the primary navigation. All same-level sub-pages should be visible.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The Footer consists of three sections: navigation, utility and base. Each section is customisable to fit the needs of the service. `}<strong parentName="p">{`It is recommended that the footer includes at least navigation and base sections.`}</strong>{` The Utility section can be omitted entirely if not needed. Parts of the sections can also be hidden.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Navigation section`}</strong>{` contains the Helsinki framed logo, the name of the service and footer navigation. Navigation section variations for more information about customising the footer navigation.`}</li>
      <li parentName="ul"><strong parentName="li">{`Utility section`}</strong>{` contains links and actions not listed in main navigation, for example, social media icons, and links to contact info, feedback, or back to top.`}</li>
      <li parentName="ul"><strong parentName="li">{`Base section`}</strong>{` is used for supplementary information, For example copyright information and links to other meta information, e.g. accessibility statement, privacy policy, terms of use, etc.`}</li>
    </ul>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The default footer uses the black text colour variant and includes all three sections.`}</p>
    <ul>
      <li parentName="ul">{`The navigation section shows the Helsinki framed logo, service name, and navigation. Navigation is optimised for up to eight menu items.`}</li>
      <li parentName="ul">{`Utility section is optimised to display up to five social media icons and three action links. An icon can be added to action links.`}</li>
      <li parentName="ul">{`Base section includes a text field for copyright or other meta information and is optimised for up to five supplementary links, depending on link length.`}</li>
    </ul>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Footer title="Helsinki Design System" footerAriaLabel="HDS Footer" mdxType="Footer">
    <Footer.Navigation navigationAriaLabel="HDS Footer navigation">
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
    </Footer.Navigation>
    <Footer.Utilities backToTopLabel="Back to top">
      <Footer.SoMe>
        <Footer.Item icon={<IconFacebook mdxType="IconFacebook" />} />
        <Footer.Item icon={<IconTwitter mdxType="IconTwitter" />} />
        <Footer.Item icon={<IconInstagram mdxType="IconInstagram" />} />
        <Footer.Item icon={<IconYoutube mdxType="IconYoutube" />} />
        <Footer.Item icon={<IconTiktok mdxType="IconTiktok" />} />
      </Footer.SoMe>
      <Footer.Item label="Contact us" />
      <Footer.Item label="Give feedback" />
    </Footer.Utilities>
    <Footer.Base copyrightHolder="Copyright" copyrightText="All rights reserved">
      <Footer.Item label="Link" />
      <Footer.Item label="Link" />
      <Footer.Item label="Link" />
      <Footer.Item label="Link" />
      <Footer.Item label="Link" />
    </Footer.Base>
  </Footer>
    </PlaygroundPreview>
    <h4 {...{
      "id": "navigation-section-variations",
      "style": {
        "position": "relative"
      }
    }}>{`Navigation section variations`}<a parentName="h4" {...{
        "href": "#navigation-section-variations",
        "aria-label": "navigation section variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Navigation section two different layout options:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Default navigation`}</strong>{` includes only the main level navigation items. The layout is optimised for up to eight menu items.`}</li>
      <li parentName="ul"><strong parentName="li">{`Minimal navigation`}</strong>{` includes only the main level navigation items. The layout is optimised for up to four menu items.`}</li>
      <li parentName="ul"><strong parentName="li">{`Sitemap navigation`}</strong>{` includes also second-level sub-pages. Sitemap can be used in footer to help the user find pages quickly from a complicated page hierarchy.`}</li>
      <li parentName="ul">{`The navigation can also be hidden altogether.`}</li>
    </ul>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Footer title="Default" footerAriaLabel="HDS Footer" mdxType="Footer">
    <Footer.Navigation navigationAriaLabel="HDS Footer navigation">
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
    </Footer.Navigation>
  </Footer>
  <br />
  <Footer title="Minimal" footerAriaLabel="HDS Footer" mdxType="Footer">
    <Footer.Navigation variant="minimal" navigationAriaLabel="HDS Footer navigation">
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
      <Footer.Item label="Item" />
    </Footer.Navigation>
  </Footer>
  <br />
  <Footer title="Sitemap" footerAriaLabel="HDS Footer" mdxType="Footer">
    <Footer.Navigation variant="sitemap" navigationAriaLabel="HDS Footer navigation">
      {[1, 2, 3, 4].map(key => <Footer.ItemGroup key={key}>
          <Footer.Item label="Item" />
          <Footer.Item subItem label="Sub item" />
          <Footer.Item subItem label="Sub item" />
          <Footer.Item subItem label="Sub item" />
          <Footer.Item subItem label="Sub item" />
          <Footer.Item subItem label="Sub item" />
          <Footer.Item subItem label="Sub item" />
        </Footer.ItemGroup>)}
    </Footer.Navigation>
  </Footer>
  <br />
  <Footer title="Hidden" footerAriaLabel="HDS Footer" mdxType="Footer" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      